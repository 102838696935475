body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Circular Std';
  src: url('./fonts/Circular/circular-std-medium-500.ttf') format('truetype'),
       url('./fonts/Circular/CircularStd-BlackItalic.otf') format('otf'),
       url('./fonts/Circular/CircularStd-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'Baguede Free';
  src: url('./fonts/BaguedeFree/BaguedeFree-Regular.otf') format('truetype'),
       url('./fonts/BaguedeFree/BaguedeFree-Italic.otf') format('otf'),
       url('./fonts/BaguedeFree/BaguedeFree-Regular.otf') format('otf'),
}
